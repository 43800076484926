<template>
  <form-view
    :url="`/user/${!dataForm.id ? 'save' : 'updateById'}`"
    :data-form="dataForm"
    v-loading="loading"
    :custom-validator="customValidator"
    :before-submit="beforeSubmit"
  >
    <div class="section-title-row">
      <div class="title">用户基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="18">
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item label-width="82px" label="用户名" prop="userName">
              <el-input v-model="dataForm.userName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真实姓名" prop="nickName">
              <el-input v-model="dataForm.nickName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25" v-if="!id">
          <el-col :span="12">
            <el-form-item label-width="82px" label="密码" prop="password">
              <el-input autocomplete="new-password" type="password" v-model="dataForm.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="confirmPassword">
              <el-input autocomplete="new-password" type="password" v-model="dataForm.confirmPassword"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item label-width="82px" label="身份证号码" prop="idCare">
              <el-input v-model="dataForm.idCare"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="员工类型" prop="userType">
              <el-select filterable v-model="dataForm.userType" @change="clearCompanyId">
                <el-option v-for="t in userTypes" :key="t.id" :label="t.name" :value="t.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="出生年月" prop="birthDate">
              <el-date-picker value-format="timestamp" v-model="dataForm.birthDate"></el-date-picker>
            </el-form-item>
          </el-col> -->
        </el-row>
        <el-row :gutter="25">
          <el-col :span="11">
            <el-form-item label-width="82px" label="合同类型" prop="contractType">
              <el-select clearable v-model="dataForm.contractType">
                <el-option v-for="item in contractTypeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="13">
            <el-form-item label="合同截止日期" label-width="98px" prop="contractTime">
              <el-date-picker clearable value-format="timestamp" v-model="dataForm.contractTime"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item label-width="82px" label="性别" prop="sex">
              <el-select v-model="dataForm.sex">
                <el-option label="男" value="男"></el-option>
                <el-option label="女" value="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="民族" prop="nation">
              <el-input v-model="dataForm.nation"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <!-- <el-col :span="12">
            <el-form-item label-width="82px" label="年龄" prop="age">
              <el-input v-model="dataForm.age"></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="联系地址" prop="userAddress">
              <el-input v-model="dataForm.userAddress"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label-width="82px" label="联系电话" prop="phone">
              <el-input type="number" v-model="dataForm.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="25">
          <el-col :span="12">
            <el-form-item label-width="82px" label="所属部门" prop="departmentId" v-if="isOwned">
              <el-cascader clearable
                           :show-all-levels="false"
                           filterable
                           placeholder="搜索"
                           v-model="dataForm.departmentId"
                           :options="departmentList"
                           :props="{value: 'id',label: 'name',children: 'list',checkStrictly: true}">
              </el-cascader>
            </el-form-item>
            <el-form-item label-width="82px" label="所属公司" prop="companyId" v-else>
              <el-select filterable v-model="dataForm.companyId">
                <el-option v-for="c in companyList" :key="c.id" :value="c.id" :label="c.companyName"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="6">
        <image-uploader
          :limit="1"
          @add="addImage($event, 'image')"
          @remove="removeImage('image')"
          :images="imageList || []"
          no-title
        ></image-uploader>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">驾驶证信息(仅针对泵车驾驶员)</div>
      <div class="line"></div>
    </div>
    <el-row>
      <el-col :span="8">
        <el-form-item label-width="120px" label="驾驶证类型" prop="drivingType">
          <el-input v-model="dataForm.drivingType"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="驾驶证到期日期" prop="drivingDate">
          <el-date-picker value-format="timestamp" v-model="dataForm.drivingDate"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="16">
        <div class="license-area">
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addImage($event, 'drivingFrontImage')"
              @remove="removeImage('drivingFrontImage')"
              :images="frontImageList || []"
              no-title
            ></image-uploader>
            <div class="title">驾驶证正面</div>
          </div>
          <div class="upload-wrapper">
            <image-uploader
              :limit="1"
              @add="addImage($event, 'drivingBackImage')"
              @remove="removeImage('drivingBackImage')"
              :images="backImageList || []"
              no-title
            ></image-uploader>
            <div class="title">驾驶证反面</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="section-title-row">
      <div class="title">角色</div>
      <div class="line"></div>
    </div>
    <el-row class="checkbox-list">
      <el-col :span="6" v-for="r in roleList" :key="r.roleId">
        <el-checkbox-group v-model="dataForm.roleIds">
          <el-checkbox :label="r.roleId">{{r.roleName}}</el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import { userTypes } from '@/utils/constants'
import dayjs from 'dayjs'

export default {
  name: 'person-person-update',

  components: { FormView, ImageUploader },

  data () {
    return {
      userTypes,
      loading: false,
      dataForm: {},
      contractTypeList: [
        { label: '无限期合同', value: 1 },
        { label: '固定合同', value: 2 }
      ],
      imageList: [],
      frontImageList: [],
      backImageList: [],
      roleList: [],
      companyList: [],
      departmentList: [],
      id: null
    }
  },

  computed: {
    isOwned () {
      return this.dataForm.userType === 1 || this.dataForm.userType === 2
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.id = this.$route.query.id
      this.loading = true
      if (id) {
        const data = await this.$http({
          url: this.$http.adornUrl('/user/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          const temp = data.datas
          temp.contractTime = dayjs(temp.contractTime).valueOf()
          temp.birthDate = dayjs(temp.birthDate).valueOf()
          temp.drivingDate = dayjs(temp.drivingDate).valueOf()
          temp.image && (this.imageList = [{ url: '/files' + temp.image }])
          temp.drivingFrontImage && (this.frontImageList = [{ url: '/files' + temp.drivingFrontImage }])
          temp.drivingBackImage && (this.backImageList = [{ url: '/files' + temp.drivingBackImage }])
          temp.roleIds = temp.roles.map(r => r.roleId)
          if (temp.secondDepartmentId) {
            temp.departmentId = [temp.departmentId, temp.secondDepartmentId]
          }
          this.dataForm = temp
          this.loading = false
        }
      } else {
        this.dataForm = {
          userName: '',
          password: '',
          idCare: '',
          sex: '',
          age: '',
          phone: '',
          userType: '',
          contractType: '',
          contractTime: '',
          nickName: '',
          confirmPassword: '',
          birthDate: '',
          nation: '',
          image: '',
          userAddress: '',
          companyId: '',
          departmentId: '',
          secondDepartmentId: '',
          drivingType: '',
          drivingDate: '',
          drivingFrontImage: '',
          drivingBackImage: '',
          isSiteUser: 0,
          roleIds: []
        }
        this.loading = false
      }
      let data = await this.$http({
        url: this.$http.adornUrl('/user/role/list'),
        method: 'post'
      })
      this.roleList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/company/listpartner'),
        method: 'post',
        data: {}
      })
      this.companyList = data.datas
      data = await this.$http({
        url: this.$http.adornUrl('/user/departmentAll/list'),
        method: 'post'
      })
      this.departmentList = data.datas.map(d => ({
        id: d.id,
        name: d.name,
        list: d.list && d.list.length ? d.list : null
      }))
    },

    addImage (path, name) {
      this.dataForm[name] = path
    },

    removeImage (name) {
      this.dataForm[name] = ''
    },

    customValidator () {
      return true
    },

    clearCompanyId () {
      this.dataForm.companyId = ''
      console.log(this.dataForm)
    },

    beforeSubmit (params) {
      console.log(params)
      console.log(this.dataForm.departmentId)
      console.log(this.dataForm.secondDepartmentId)
      const ids = this.dataForm.departmentId
      if (params.userType !== 3 && ids) {
        if (ids instanceof Array) {
          params.departmentId = ids[0]
          params.secondDepartmentId = ids[1]
        } else {
          params.departmentId = ids
          params.secondDepartmentId = null
        }
      } else {
        params.departmentId = null
        params.secondDepartmentId = null
      }
      return params
    }
  }
}
</script>

<style lang="scss" scoped>
  .bracket-area {
    .el-form-item__content {
      white-space: nowrap;

      .el-input {
        width: 80%;
      }

      .el-input + label {
        margin-left: 10px;
      }
    }
  }

  .flex-row {
    margin: 20px 0;
    display: flex;
    align-items: center;

    .el-select,
    .el-date-editor {
      width: 150px;
      flex: 0 0 auto;
    }

    label {
      margin: 0 10px;
      white-space: nowrap;
    }

    .el-input.remark {
      flex: 1;
      width: auto;
    }

    .icon {
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #999;
      }
    }
  }

  .license-area {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
  }
</style>
